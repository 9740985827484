




import { Component, Vue, Prop } from 'vue-property-decorator'

import './scss/BasePreloader.scss';

@Component({
    name: 'BasePreloader',
})

export default class BasePreloader extends Vue {
    @Prop({
        default: 'default',
        validator(value: string) {
            return ['button', 'small', 'medium', 'default', 'big'].includes(value);
        },
    }) type!: 'button' | 'small' | 'medium' | 'default' | 'big';
}
